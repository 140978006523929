import store from "../store";
import Vue from "@/main";
import { GET_CUSTOM_FONTS_BY_USER_EMAIL, GET_PROD_FONTS } from "@/gql/";

const avalableFontsList = store.getters["avalableFontsList"];

export default async function fetchFont(fontLink, fontName) {
  const owner = store.getters["owner"];
  let customFonts = store.getters["customFonts"];
  if (avalableFontsList.includes(fontLink)) {
    return;
  }
  if (fontLink){
    const new_font = new FontFace(fontName, `url(${fontLink})`);
      const loaded_face = await new_font.load();
      document.fonts.add(loaded_face);
      store.dispatch("addToAvalable", fontName);
      return
  }

  if (!customFonts.length) {
    const { data } = await Vue.$apollo.query({
      query: GET_CUSTOM_FONTS_BY_USER_EMAIL,
      variables: {
        email: owner
      }
    });
    store.dispatch("updateCustomFonts", data.upload_my_font);
    customFonts = store.getters["customFonts"];
  }
  // user fonts
  let isMyFont = false;
  if (customFonts.length) {
    for (let f of customFonts) {
      const _name = f.name.replace(/\.(ttf|otf)/g, "")
      if (_name === fontName) {
        const new_font = new FontFace(fontName, `url(${f.content})`);
        const loaded_face = await new_font.load();
        document.fonts.add(loaded_face);
        store.dispatch("addToAvalable", fontName);
        isMyFont = true;
      }
    }
  }
  if (isMyFont) return;

  // prod fonts
  try {
    const { data } = await Vue.$apollo.query({
      query: GET_PROD_FONTS,
      variables: {
        name: fontName
      }
    });
    const font = data.font[0];
    if (font) {
      const cdn1 = process.env.VUE_APP_CDN_SPACES_ULR;
      const cdn2 = process.env.VUE_APP_SPACES_URL;
      const space = process.env.VUE_APP_DEFAULT_CDN_SPACES_URL;

      font.content.replace(cdn1, space).replace(cdn2, space);

      const new_font = new FontFace(fontName, `url(${font.content})`);
      const loaded_face = await new_font.load();
      document.fonts.add(loaded_face);
      store.dispatch("addToAvalable", fontName);
    } else console.log("font is not found!");
  } catch (error) {
    console.log(error);
  }
}
