import { UPDATE_RESPOND, GET_ATTACHMENT, LOG } from "@/gql/";
import { apolloClient as apollo } from "@/plugins/vue-apollo";
import store from "@/store";
import { saveAs } from "file-saver";

export default async function clickAction({
  widget,
  widgetName,
  widgetIdx,
} = {}) {
  const widgetType = widget.name;
  const actionType = widget.configs.actionType;
  const linkTarget = widget.configs.linkTarget;
  const pageTarget = widget.configs.pageTarget;
  const attachmentId = widget.configs.attachmentId;
  const url = widget.configs.url;
  // const socialNetwork = widget.configs.socialNetwork;
  if (actionType === "no action") return;
  else if (actionType === "upload file") {
    store.dispatch("showPopup", {
      visible: true,
      type: "uploadPopup",
      data: {
        widgetIdx,
        widgetName,
        actionType,
        widgetType,
      },
    });
    // } else if (actionType === "social sharing") {
    //   await store.dispatch("updateSocialNetworkForSharing", socialNetwork);
    //   const socialShareBtn = document.querySelector("#social-share-btn");
    //   if (!socialShareBtn) return;
    //   socialShareBtn.click();
  } else if (actionType === "download file") {
    if (!attachmentId) return;
    const res = await apollo.query({
      query: GET_ATTACHMENT,
      variables: {
        id: attachmentId,
      },
    });
    const attachment = res.data.upload_my_attachment[0];
    if (!attachment) return;
    markStep(widgetType, widgetIdx, widgetName, actionType, { attachment });
    await updateRespond();
    saveAs(attachment.content, attachment.name);
    // const a = document.createElement("a");
    // a.target = "_blank";
    // a.href = attachment.content;
    // a.setAttribute("download", "123.xls");
    // console.log(a, attachment);
    // a.click();
  } else if (actionType === "external link") {
    if (!url) return;
    markStep(widgetType, widgetIdx, widgetName, actionType, { url });
    await updateRespond();
    const a = document.createElement("a");
    const _url = url.match(/(http|https):\/\//) ? url : `https://${url}`;
    a.href = _url;
    if (linkTarget === "open in a new tab") a.target = "_blank";
    a.click();
  } else if (actionType === "internal link") {
    if (pageTarget > store.getters["step"]) {
      const isValid = await store.dispatch("validate");
      if (!isValid) return;
    }
    markStep(widgetType, widgetIdx, widgetName, actionType, { pageTarget });
    await updateRespond();
    onChangePage(pageTarget);
  }
}

function onChangePage(pageTarget) {
  const stepsLength = store.getters["stepsLength"];
  const currentStep = store.getters["step"];
  const step = pageTarget < 1 || pageTarget > stepsLength ? 0 : pageTarget - 1;
  if (step === currentStep) return;
  store.dispatch("visibleLoading", true);
  store.dispatch("updateStep", step);
}

export async function updateRespond() {
  const respond = store.getters.respond;
  const interactionId = store.getters.interactionId;
  const id = store.getters.respondId;
  if (!respond.length) return;
  await apollo
    .mutate({
      mutation: UPDATE_RESPOND,
      variables: {
        id,
        device: navigator.userAgent,
        content: respond,
        myInteractionId: interactionId,
      },
    })
    .then(() => {
      if (store.getters["isSentRespond"]) return;
      apollo.mutate({
        mutation: LOG,
        variables: {
          userId: store.getters["ownerId"],
          action: "interaction respond",
          data: {
            id,
            device: navigator.userAgent,
            myInteractionId: interactionId,
          },
        },
      });
      store.commit("isSentRespond");
    });
}

function markStep(widgetType, widgetIdx, widgetName, actionType, actionData) {
  const respond = store.getters["respond"];
  const step = store.getters["step"];
  if (!respond[step]) respond[step] = [];
  respond[step][widgetIdx] = {
    widgetType,
    widgetName,
    actionType,
    actionData,
  };
}
