const styles = {
  "general-styles": {
    top: { prop: "top", value: (v) => `${v}px` },
    left: { prop: "left", value: (v) => `${v}px` },
    width: [
      { prop: "width", value: (v) => `${v}px` },
      { prop: "max-width", value: (v) => `${v}px` },
    ],
    height: [
      { prop: "height", value: (v) => `${v}px` },
      { prop: "max-height", value: (v) => `${v}px` },
    ],
    // ? transform
    angle: {
      prop: "transform",
      value: (v, s) =>
        s.transform ? `${s.transform} rotate(${v}deg)` : `rotate(${v}deg)`,
    },
    scaleX: {
      prop: "transform",
      value: (v, s) =>
        s.transform ? `${s.transform} scaleX(${v})` : `scaleX(${v})`,
    },
    scaleY: {
      prop: "transform",
      value: (v, s) =>
        s.transform ? `${s.transform} scaleY(${v})` : `scaleY(${v})`,
    },
    originX: {
      prop: "transformOrigin",
      value: (v, s) =>
        s.transformOrigin ? `${v} ${s.transformOrigin}` : `${v}`,
    },
    originY: {
      prop: "transformOrigin",
      value: (v, s) =>
        s.transformOrigin ? `${s.transformOrigin} ${v}` : `${v}`,
    },
  },
  "text-styles": {
    // ? text
    shadow: {
      prop: "textShadow",
      value: (v) =>
        `${v.offsetX || 0}px ${v.offsetY || 0}px ${v.blur}px ${v.color}`,
    },
    fill: { prop: "color", value: (v) => v },
    fontSize: { prop: "fontSize", value: (v) => `${v}px` },
    fontWeight: { prop: "fontWeight", value: (v) => v },
    fontStyle: { prop: "fontStyle", value: (v) => v },
    lineHeight: { prop: "lineHeight", value: (v) => `${v}` },
    textAlign: { prop: "textAlign", value: (v) => v },
    strokeWidth: {
      prop: "textStrokeWidth",
      value: (v, s, w) => `${w.stroke && v}px`,
    },
    stroke: { prop: "textStrokeColor", value: (v) => v },
    underline: {
      prop: "textDecoration",
      value: (v) => (v ? `underline` : "normal"),
    },
  },
  "image-styles": {
    stroke: { prop: "--border-color", value: (v) => v },
    strokeWidth: [
      { prop: "--border-weight", value: (v, s, w) => `${v / w.scaleX}px` },
      { prop: "padding", value: (v, s, w) => `${v / w.scaleX}px` },
    ],
    strokeDashArray: [
      { prop: "--dash-size", value: (v, s, w) => `${v[0] / w.scaleX}px` },
      { prop: "--gap-size", value: (v, s, w) => `${v[0] / w.scaleX}px` },
    ],
  },
  "video-styles": {
    width: {
      prop: "width",
      value: (v, s, w) => `${v - w.configs.strokeWidth}px`,
    },
    height: {
      prop: "height",
      value: (v, s, w) => `${v - w.configs.strokeWidth}px`,
    },
    configs: [
      { prop: "--border-color", value: (v) => v.stroke },
      { prop: "--border-weight", value: (v) => `${v.strokeWidth}px` },
      { prop: "padding", value: (v) => `${v.strokeWidth}px` },
      { prop: "--dash-size", value: (v) => `${v.strokeDashArray[0] || 300}px` },
      { prop: "--gap-size", value: (v) => `${v.strokeDashArray[0] || 300}px` },
    ],
  },
  "scale-styles": {
    configs: [
      { prop: "--active-bg-color", value: (v) => v.activeBgColor },
      { prop: "--bg-color", value: (v) => v.bgColor },
      { prop: "color", value: (v) => v.fontColor },
      { prop: "fontSize", value: (v) => `${v.fontSize}px` },
      {
        prop: "flex-direction",
        value: (v) => (v.orientation === "vertical" ? "column" : "row"),
      },
    ],
  },
  "stars-styles": {
    configs: [
      {
        prop: "flex-direction",
        value: (v) => (v.orientation === "vertical" ? "column" : "row"),
      },
    ],
  },
};

export default function setStyles(widget, { setGeneral = true } = {}) {
  if (!widget) return;
  const stylesObject = {};
  const type = widget.name;
  for (let [param, value] of Object.entries(widget)) {
    // console.log(param, value);
    if (setGeneral)
      setTypeStyles("general", param, value, stylesObject, widget);
    setTypeStyles(type, param, value, stylesObject, widget);
  }
  return stylesObject;
}

function setTypeStyles(type, param, value, stylesObject, widget) {
  const existProp = styles[`${type}-styles`] && styles[`${type}-styles`][param];
  if (existProp && value !== null)
    if (Array.isArray(existProp)) {
      existProp.forEach(
        (p) => (stylesObject[p.prop] = p.value(value, stylesObject, widget))
      );
    } else {
      stylesObject[existProp.prop] = existProp.value(
        value,
        stylesObject,
        widget
      );
    }
}
