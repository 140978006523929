<template>
  <div class="scale-widget-container" ref="scaleContent" :style="itemStyles">
    <div
      class="scale-content"
      :class="{ active: n === activeScore }"
      v-for="n of range"
      :key="n"
      @click="onClick(n)"
    >
      {{ n }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import setStyles from "@/utils/setStyles";
import fetchFont from "@/utils/fetchFont";
import { updateRespond } from "@/utils/clickAction";

export default {
  name: "SvScaleWidget",
  props: ["widgetIdx"],
  data() {
    return {
      activeScore: null,
    };
  },
  computed: {
    ...mapGetters([
      "activeStep",
      "interaction",
      "step",
      "respond",
      "interactionId",
      "respondId",
    ]),
    widget() {
      return (
        this.activeStep &&
        this.activeStep.objects &&
        this.activeStep.objects[this.widgetIdx]
      );
    },
    itemStyles() {
      return setStyles(this.widget);
    },
    orientation() {
      return this.widget.configs.orientation;
    },
    range() {
      if (this.widget.configs && this.widget.configs.rangeArray) {
        const start = this.widget.configs.rangeArray[0];
        const end = this.widget.configs.rangeArray[1];
        const rangeArray = [];
        if (start > end) for (let i = start; i >= end; i--) rangeArray.push(i);
        else for (let i = start; i <= end; i++) rangeArray.push(i);
        return rangeArray;
      } else return [];
    },
  },
  methods: {
    async onClick(value) {
      this.activeScore = value;
      await this.$store.dispatch("updateRespond", {
        data: {
          widgetType: "scale",
          widgetName: this.orientation,
          actionType: "scale",
          actionData: value,
        },
        idx: this.widgetIdx,
        step: this.step,
      });
      updateRespond();
    },
  },
  mounted() {
    const fontFamily = this.widget.configs.fontFamily.replace(
      /\.(ttf|otf)/g,
      ""
    );
    const fontLink = this.widget.configs.fontLin;

    if (fontFamily)
      fetchFont(fontLink, fontFamily).then(() => {
        this.$refs.scaleContent.style.fontFamily = fontFamily;
        this.$emit("hideLoader");
      });
  },
};
</script>

<style scoped>
.scale-widget-container {
  display: flex;
  justify-content: space-between;
}
.scale-content {
  width: 30px;
  height: 30px;
  background: var(--bg-color);
  text-align: center;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.1s all;
}
.scale-content.active,
.scale-content:hover {
  background: var(--active-bg-color);
}
</style>
